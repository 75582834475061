import getConfig from 'next/config'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Button, Card, Flex, Header, Icon, Spacer, Text, VStack } from 'ui-lib'

import { LoginForm } from '@/src/components/auth'
import { CardAdornments } from '@/src/components/misc/card-Adornments'
import { KeepLogo } from '@/src/components/misc/keep-logo'
import { RecaptchaProvider } from '@/src/contexts/misc'
import { useColorfulBackground } from '@/src/hooks/misc'
import { redirectIfAuthenticated } from '@/src/utils/redirectIfAuthenticated'
import { isUnsupportedWebView } from '@/src/utils/webview'
import { NextConfig } from '@/types/helpers'

const { publicRuntimeConfig } = getConfig() as NextConfig

export const getServerSideProps = redirectIfAuthenticated

const LoginPage = () => {
  const { getPageContainerProps, getPageContainerCenterProps } = useColorfulBackground()
  const [hideGoogle, setHideGoogle] = useState(false)
  const { query, isReady } = useRouter()

  useEffect(() => {
    if (isReady) {
      setHideGoogle(isUnsupportedWebView())
    }
  }, [isReady])

  return (
    <Flex direction="column" height="100vh" {...getPageContainerProps()}>
      <Head>
        <title>Keep - Login</title>
      </Head>

      <Header display={{ base: 'none', lg: 'flex' }} backdropFilter="blur(7.5px)">
        <Spacer />

        <Button
          as={Link}
          variant="link"
          color="text-primary"
          href={{ pathname: '/how-will-you-use-keep', query }}
          rightIcon={<Icon icon="chevron-right" h={4} w={4} align="center" />}
        >
          Create an Account
        </Button>
      </Header>

      <Flex
        justifyContent="center"
        alignItems="center"
        as="main"
        w="100vw"
        height={{ base: '100vh', lg: 'calc(100vh - 53.62px)' }}
        {...getPageContainerCenterProps()}
      >
        <Flex
          maxW={{
            md: '440px',
          }}
          h={{
            base: '100vh',
            md: 'auto',
          }}
          minH="650px"
          w="100%"
          overflow="hidden"
        >
          <Card
            bg="bg-primary"
            variant={'outline'}
            w="full"
            h="full"
            justifyContent={{ base: 'flex-start', md: 'center' }}
            borderRadius={{
              base: '0px',
              md: '16px',
            }}
            pt={{ base: 16, md: 8 }}
            pb={8}
            overflow="hidden"
          >
            <CardAdornments />
            <VStack direction={'column'} gap={4} mb={6}>
              <KeepLogo />

              <Flex flexDir="column" textAlign="center" gap={{ base: 0, md: 1 }}>
                <Text textStyle="headline-3">Get started</Text>
                <Text
                  color="text-secondary"
                  textStyle="paragraph-lg"
                  textAlign="center"
                  sx={{
                    textWrap: 'balance',
                  }}
                >
                  Don&apos;t have a Keep account yet?{' '}
                  <Button
                    id="sign-up-link"
                    as={Link}
                    variant="link"
                    color="text-primary"
                    href={{
                      pathname: '/how-will-you-use-keep',
                      query,
                    }}
                  >
                    Sign Up
                  </Button>
                </Text>
              </Flex>
            </VStack>
            <RecaptchaProvider siteKey={publicRuntimeConfig.recaptchaSiteKey}>
              <LoginForm hideGoogle={hideGoogle} />
            </RecaptchaProvider>
          </Card>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default LoginPage
